import React, { useContext, useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { parseAsContent } from 'utils/parser';
import { useLockBodyScroll } from 'utils/hooks/useLockBodyScroll';

import Link from 'components/common/Link';
import { AgegateContext } from 'components/container/Agegate';
import FormField from '../../common/Form/FormField/FormField';

import {
  AgegateStyled,
  InnerStyled,
  IconStyled,
  ContentStyled,
  HeadlineStyled,
  TextStyled,
  ButtonWrapStyled,
  ButtonAcceptStyled,
  FooterStyled,
  FormWrapper,
} from './AgegateStyled';

function Agegate({ data }) {
  const {
    globalAgeGateHeadline,
    globalAgeGateText,
    globalAgeGateYes,
    globalAgeGateFooterLinks,
    globalAgeGateFooterExternalLinks,
  } = data;

  const [legalAge, setLegalAge] = useState(false);
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const { confirmedAge, handleAcceptClick } = useContext(AgegateContext);

  useLockBodyScroll(!confirmedAge);

  const getAge = () => {
    let yearInMilli = 31556952000;
    let today = new Date();
    let birthday = new Date(`${year}-${month}-${day}`);
    let diff = (today - birthday) / yearInMilli;
    //console.log(today, birthday, diff);

    if (diff >= 21 && day !== '' && month !== '') {
      setLegalAge(true);
    } else {
      setLegalAge(false);
      setDay('');
      setMonth('');
      setYear('');
    }
    //console.log(legalAge);
  };

  useEffect(() => {
    if (day > '31' || day === '00') {
      setDay('');
    }
    if (month > 12 || month === '00') {
      setMonth('');
    }
    if (year > new Date().getFullYear() - 21) {
      setYear('');
    }
    if (year.length >= 4) {
      getAge();
    }
  }, [day, month, year]);

  // Only show restrictions prompt when cookies got loaded and the values are "false". ~RS
  return !confirmedAge ? (
    <AgegateStyled>
      <InnerStyled>
        <IconStyled type="logoWarsteiner" />
        <ContentStyled>
          <HeadlineStyled>{globalAgeGateHeadline}</HeadlineStyled>
          <FormWrapper>
            <FormField
              value={day}
              type="text"
              placeholder="DD"
              maxLength="2"
              onChange={(e) => setDay(e.target.value.replace(/\D/g, ''))}
            />
            <FormField
              value={month}
              type="text"
              pattern="[0-9]"
              placeholder="MM"
              maxLength="2"
              onChange={(e) => setMonth(e.target.value.replace(/\D/g, ''))}
            />
            <FormField
              value={year}
              type="text"
              pattern="[0-9]"
              placeholder="YYYY"
              maxLength="4"
              onChange={(e) => setYear(e.target.value.replace(/\D/g, ''))}
            />
          </FormWrapper>
        </ContentStyled>
        <ButtonWrapStyled>
          <ButtonAcceptStyled
            disabled={!legalAge}
            onClick={() => handleAcceptClick()}
          >
            {globalAgeGateYes}
          </ButtonAcceptStyled>
        </ButtonWrapStyled>
        <TextStyled element="div">
          {parseAsContent(globalAgeGateText?.content)}
        </TextStyled>
      </InnerStyled>
      {globalAgeGateFooterLinks && (
        <FooterStyled>
          {globalAgeGateFooterLinks.map(({ id, fullUri, title }) => (
            <Link key={id} to={fullUri} target="_blank">
              {title}
            </Link>
          ))}
          {globalAgeGateFooterExternalLinks.map((entry, index) => (
            <Link key={entry.id} to={entry.agegateExternalLink.url} target="_blank">
              {entry.agegateExternalLink.customText}
            </Link>
          ))}
        </FooterStyled>
      )}
    </AgegateStyled>
  ) : null;
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query AgegateQuery {
        craft {
          entry(siteId: 4) {
            ... on Craft_AgegateUs {
              title
              globalAgeGateYes
              globalAgeGateHeadline
              globalAgeGateText {
                content
              }
              globalAgeGateFooterLinks {
                id
                fullUri
                title
              }
              globalAgeGateFooterExternalLinks {
                __typename
                ... on Craft_GlobalAgeGateFooterExternalLinksLink {
                  id
                  agegateExternalLink {
                    url
                    target
                    customText
                  }
                }
              }
            
            }
          }
        }
      }
    `}
    render={(data) => <Agegate data={data?.craft?.entry} {...props} />}
  />
);
