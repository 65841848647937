import React, { useState, Children } from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';

import PageTypeContext from 'utils/context/PageTypeContext';
import { enableTracking } from 'utils/tracking';

import Agegate, { AgegateContext } from 'components/container/Agegate';
import Cookiegate, { CookiegateContext } from 'components/container/Cookiegate';
import Header from 'components/container/Header';
import Footer from 'components/container/Footer';
import Menu from 'components/container/Menu';
import Favicons from 'components/common/Favicons';
import SwitchTheme from 'components/common/SwitchTheme';

import './globalStyles';
import {
  LayoutStyled,
  LayoutOverlayStyled,
  LayoutOverlayIconStyled,
} from './LayoutStyled';

export default function Layout({ children, pageContext }) {
  const [menuOpen, setMenuOpen] = useState(false);

  // Set Agegate cookies here since hey lets trash this completly unrelated component right? ~DH
  const [agegateContext, setAgegateContext] = useState({
    acceptedCookies: cookie.load('accepted_cookies') === 'true',
    confirmedAge: cookie.load('confirmed_age') === 'true',
    handleAcceptClick: () => {
      const expires = new Date();
      const now = new Date();
      expires.setDate(now.getDate() + 14);
      cookie.save('confirmed_age', true, { expires, path: '/' });
      setAgegateContext({
        confirmedAge: true,
      });
    },
  });

  // Same for Cookiegate right? ~DH
  const [cookiegateContext, setCookiegateContext] = useState({
    acceptedCookies: cookie.load('accepted_cookies') === 'true',
    confirmedAge: cookie.load('confirmed_age') === 'true',
    handleAcceptClick: (trackingOptIn) => {
      const expires = new Date();
      const now = new Date();
      expires.setDate(now.getDate() + 14);
      cookie.save('accepted_cookies', true, { expires, path: '/' });

      setCookiegateContext({
        acceptedCookies: true,
      });

      // Init tracking if the user opts in.
      if (trackingOptIn === true) {
        enableTracking();
      }
    },
  });

  const onlyChild = Children.only(children);
  // persist key between beerVarietiesCats renders
  // this prevents
  if (pageContext?.type === 'productsUs') {
    onlyChild.key = '$productsUs';
  }

  return (
    <PageTypeContext.Provider value={pageContext?.type}>
      <CookiegateContext.Provider value={cookiegateContext}>
        <AgegateContext.Provider value={agegateContext}>
          <SwitchTheme>
            <Favicons />
            <LayoutStyled className="preloadOverlayWrap">
              <Header
                menuOpen={menuOpen}
                onLogoClick={() => setMenuOpen(false)}
                onBurgerClick={() => setMenuOpen(!menuOpen)}
                darkSiteActive={pageContext?.darkSiteActive}
              />
              {onlyChild}
              {pageContext?.type !== 'productsUs' ? <Footer /> : null}
              <LayoutOverlayStyled className="preloadOverlay">
                <LayoutOverlayIconStyled type="logoWarsteiner" />
              </LayoutOverlayStyled>
              <Menu
                menuOpen={menuOpen}
                onMenuItemClick={() => setMenuOpen(false)}
                onMenuBgClick={() => setMenuOpen(!menuOpen)}
              />
              {pageContext?.disableCookieOverlay === true ? null : (
                <Cookiegate />
              )}
              <Agegate />
            </LayoutStyled>
          </SwitchTheme>
        </AgegateContext.Provider>
      </CookiegateContext.Provider>
    </PageTypeContext.Provider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
